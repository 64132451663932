<template>
    <div class="w-full">
        <div id="autocomplete" class="relative w-full lg:w-64 max-w-sm mx-auto"></div>
    </div>
</template>
<style>
:root {
    --aa-primary-color-rgb: 79, 70, 229; /* bg-indigo-500 */
    --aa-selected-color-rgb: 248, 250, 251; /* bg-gray-50 */
    --aa-selected-color-alpha: 1;
    --aa-description-highlight-background-color-rgb: 254, 252, 232; /* bg-yellow-50 */
    --aa-description-highlight-background-color-alpha: 1;
}

.aa-DetachedContainer--modal .aa-PanelLayout {
    padding-bottom: 0 !important;
}

section.aa-Source[data-autocomplete-source-id="links"] {
    position: sticky;
    bottom: 0;
    padding: 0.5rem 0;
    background-color: #FFF;
}
</style>

<script setup>
import {ref, onMounted, h, Fragment, render, watch} from 'vue';
import {usePage, router} from '@inertiajs/vue3';
import {instantMeiliSearch} from "@meilisearch/instant-meilisearch/src";
import * as autocomplete from '@algolia/autocomplete-js';
import {getMeilisearchResults} from "@meilisearch/autocomplete-client/src";
import '@algolia/autocomplete-theme-classic';
import * as recentSearches from '@algolia/autocomplete-plugin-recent-searches';
import {imageSize} from "@/mixins";
import {event} from "vue-gtag";

const {searchClient} = instantMeiliSearch(
    import.meta.env.VITE_MEILISEARCH_HOST,
    import.meta.env.VITE_MEILISEARCH_KEY,
    {
        meiliSearchParams: {
            showRankingScore: true,
        },
    },
);

const search = ref(usePage().props.query);

function header(html, text) {
    return html`
        <div class="aa-SourceHeader text-gray-500">${text}</div>`;
}

onMounted(() => {
    const recentSearchesPlugin = recentSearches.createLocalStorageRecentSearchesPlugin({
        key: 'RECENT_SEARCH',
        limit: 5,
        transformSource({source}) {
            return {
                ...source,
                getItemUrl({item}) {
                    return route('products.browse', {query: item.label});
                },
                onSelect(event) {
                    event.navigator.navigate(event);
                },
                templates: {
                    ...source.templates,
                    header({html}) {
                        return header(html, 'Recent Searches');
                    },
                },
            };
        },
    });
    const {setIsOpen} = autocomplete.autocomplete({
        container: '#autocomplete',
        placeholder: 'Search...',
        initialState: {
            query: search.value,
        },
        detachedMediaQuery: '',
        insights: false,
        openOnFocus: true,
        plugins: [recentSearchesPlugin],
        getSources({query}) {
            if (!query) {
                return [];
            }
            search.value = query;
            return [
                ...[{
                    id: 'fabricLines',
                    index: 'fabric-lines',
                    title: 'Fabric Lines',
                }, {
                    id: 'manufacturers',
                    index: 'manufacturers',
                    title: 'Manufacturers',
                }, {
                    id: 'designers',
                    index: 'designers',
                    title: 'Designers',
                }, {
                    id: 'categories',
                    index: 'categories',
                    title: 'Categories',
                }].map((search) => {
                    return {
                        sourceId: search.id,
                        getItems() {
                            return getMeilisearchResults({
                                searchClient,
                                queries: [
                                    {
                                        indexName: search.index,
                                        query,
                                        params: {
                                            hitsPerPage: 4,
                                            attributesToHighlight: ['title'],
                                            filters: 'product_count > 0',
                                        },
                                    },
                                ],
                            });
                        },
                    }
                }),
                {
                    sourceId: 'products',
                    getItems() {
                        return getMeilisearchResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: 'products',
                                    query,
                                    params: {
                                        hitsPerPage: 5,
                                        attributesToHighlight: ['title', 'subtitle'],
                                        filters: 'in_stock = true',
                                    },
                                },
                            ],
                        });
                    },
                },
                {
                    sourceId: 'links',
                    getItems({query}) {
                        return [
                            {label: 'My Orders', url: route('orders.index')},
                            {label: 'My Profile', url: route('profile.edit')},
                            {label: 'Cart', url: route('cart.show')},
                            {label: 'Favorites', url: route('products.favorites')},
                            {label: 'View all results for: ' + query, url: route('products.browse', {query})},
                        ].filter(({label}) =>
                            label.toLowerCase().includes(query.toLowerCase())
                        );
                    },
                    getItemUrl({item}) {
                        return item.url;
                    },
                    onSelect(event) {
                        event.navigator.navigate(event);
                    },
                    templates: {
                        item({item, html}) {
                            return html`
                                <div class="p-1">${item.label}</div>`;
                        },
                    },
                },
            ];
        },
        reshape({sourcesBySourceId}) {
            const {
                products,
                fabricLines,
                manufacturers,
                designers,
                categories,
                ...rest
            } = sourcesBySourceId;

            const allResults = (products?.getItems() ?? []).concat(fabricLines?.getItems() ?? [], manufacturers?.getItems() ?? [], designers?.getItems() ?? [], categories?.getItems() ?? []);
            allResults.sort((a, b) => b._rankingScore - a._rankingScore);
            const sources = Object.values(rest);
            if (allResults.length > 0) {
                sources.unshift({
                    ...products,
                    getItems() {
                        return allResults;
                    },
                    onSelect(event) {
                        event.navigator.navigate(event);
                    },
                    getItemUrl({item}) {
                        if (item.product_id) {
                            return route('product.show', item);
                        } else {
                            return route(item.route, item.parameters);
                        }
                    },
                    templates: {
                        header({html}) {
                            return header(html, 'Quick Results');
                        },
                        item({item, components, html}) {
                            let imageHtml = '';
                            if (item.imageSrc) {
                                imageHtml = html`
                                    <div class="aa-ItemIcon aa-ItemIcon--noBorder !h-24 !w-24">
                                        <img
                                            class="!h-full !w-full !object-cover !object-center !max-w-none !max-h-none"
                                            src="${imageSize(item.imageSrc, 300)}"
                                            alt="${item.title}"
                                            width="40"
                                            height="40"
                                        />
                                    </div>`;
                            }
                            return html`
                                <div class="aa-ItemWrapper">
                                    <div class="aa-ItemContent">
                                        ${imageHtml}
                                        <div class="aa-ItemContentBody p-1">
                                            <div class="aa-ItemContentTitle">
                                                ${components.Highlight({
                                                    hit: item,
                                                    attribute: 'title',
                                                })}
                                            </div>
                                            <div class="text-sm mt-0">
                                                ${components.Snippet({
                                                    hit: item,
                                                    attribute: 'subtitle',
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>`;
                        },
                    },
                });
            }
            return sources;
        },
        onSubmit(params) {
            params.event.preventDefault();
            router.visit(route('products.browse', {query: params.state.query}));
        },
        renderer: {createElement: h, Fragment, render},
        navigator: {
            navigate(event) {
                setIsOpen(false);
                router.visit(event.itemUrl, {preserveState: true});
            },
        },
    });
});

const searchTimeout = ref(null);

watch(search, (newSearch) => {
    if (searchTimeout.value) {
        clearTimeout(searchTimeout.value);
    }
    searchTimeout.value = setTimeout(() => {
        event('view_search_results', {
            search_term: newSearch,
        });
    }, 1000);
});

</script>
