<template>
    <footer class="bg-gray-800" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div class="xl:grid xl:grid-cols-3 xl:gap-8">
                <div class="grid grid-cols-2 gap-8 xl:col-span-2">
                    <div class="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3 class="text-base font-bold text-white">Manufacturers</h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li v-for="item in navigation.manufacturers" :key="item.name">
                                    <Link :href="item.href" class="text-base text-gray-300 hover:text-white">{{
                                            item.name
                                        }}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-12 md:mt-0">
                            <h3 class="text-base font-bold text-white">Designers</h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li v-for="item in navigation.designers" :key="item.name">
                                    <Link :href="item.href" class="text-base text-gray-300 hover:text-white">{{
                                            item.name
                                        }}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3 class="text-base font-bold text-white">Categories</h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li v-for="item in navigation.categories" :key="item.name">
                                    <Link :href="item.href" class="text-base text-gray-300 hover:text-white">{{
                                            item.name
                                        }}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div class="mt-12 md:mt-0">
                            <h3 class="text-base font-bold text-white">Specials</h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li v-for="item in navigation.specials" :key="item.name">
                                    <Link :href="item.href" class="text-base text-gray-300 hover:text-white">{{
                                            item.name
                                        }}
                                    </Link>
                                </li>
                            </ul>
                            <h3 class="text-base mt-6 font-bold text-white">Other Cuts</h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li v-for="item in navigation.cuts" :key="item.name">
                                    <Link :href="item.href" class="text-base text-gray-300 hover:text-white">{{
                                            item.name
                                        }}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="mt-8 xl:mt-0">
                    <h3 class="text-base font-bold text-white">Subscribe to our newsletter</h3>
                    <p class="mt-4 text-base text-gray-300">The latest news, products, and sales, sent to your
                        inbox.</p>
                    <form class="mt-4 sm:flex sm:max-w-md" action="//theoldcountrystore.createsend.com/t/r/s/jhtkjd/"
                          method="post">
                        <label for="email-address" class="sr-only">Email address</label>
                        <input type="email" name="cm-jhtkjd-jhtkjd" id="email-address" autocomplete="email" required=""
                               class="w-full min-w-0 appearance-none rounded-md border border-transparent bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:border-white focus:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                               placeholder="Enter your email"/>
                        <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                            <button type="submit"
                                    class="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-500 py-2 px-4 text-base font-medium text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800">
                                Subscribe
                            </button>
                        </div>
                    </form>
                    <div class="flex flex-row">
                        <div class="w-1/2">
                            <h3 class="text-base mt-6 font-bold text-white">About</h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li>
                                    <Link :href="route('about')"
                                          class="text-gray-300 hover:text-white">
                                        Our Story
                                    </Link>
                                </li>
                                <li>
                                    <Link :href="route('shipping')"
                                          class="text-gray-300 hover:text-white">
                                        Shipping &amp; Returns
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div class="w-1/2">
                            <h3 class="text-base mt-6 font-bold text-white">Legal Info</h3>
                            <ul role="list" class="mt-4 space-y-4">
                                <li>
                                    <a href="https://www.iubenda.com/privacy-policy/45697639" target="_blank"
                                       class="text-base text-gray-300 hover:text-white" title="Privacy Policy ">Privacy
                                        Policy</a>
                                </li>
                                <li>
                                    <a href="https://www.iubenda.com/privacy-policy/45697639/cookie-policy"
                                       target="_blank" class="text-base text-gray-300 hover:text-white"
                                       title="Cookie Policy ">Cookie Policy</a>
                                </li>
                                <li>
                                    <a href='#' class="iubenda-cs-preferences-link text-base text-gray-300 hover:text-white">Privacy Choices</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
                <div class="flex flex-col md:order-2 items-center md:items-end">
                    <a class="text-gray-300" href="tel:18888288218">1-888-828-8218</a>
                    <a class="text-gray-400"
                       href="mailto:customerservice@oldcountrystorefabrics.com">CustomerService@oldcountrystorefabrics.com</a>
                    <div class="mt-2 flex space-x-6">
                        <Link v-for="item in navigation.social" :key="item.name" :href="item.href" target="_blank"
                              class="text-gray-400 hover:text-gray-300">
                            <span class="sr-only">{{ item.name }}</span>
                            <component :is="item.icon" class="h-6 w-6" aria-hidden="true"/>
                        </Link>
                    </div>
                </div>
                <div class="flex flex-col mt-8 md:order-1 md:mt-0">
                    <p class="text-gray-400 text-base">&copy; 2024 Old Country Store Fabrics. All
                        rights reserved.</p>
                    <p class="text-gray-300 text-sm">3510 Old Philadelphia Pike, Intercourse, PA 17534</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>
import {defineComponent, h, inject} from 'vue'
import {usePage, Link} from "@inertiajs/vue3";

const route = inject('route');
const manufacturers = usePage().props.popular.manufacturer;
const designers = usePage().props.popular.designer;
const categories = usePage().props.popular.category;

const navigation = {
    manufacturers: manufacturers.map(manufacturer => ({
        name: manufacturer.name,
        href: manufacturer.url,
    })).concat({
        name: 'View All',
        href: route('collections.index', 'manufacturer'),
    }),
    designers: designers.map(designer => ({
        name: designer.name,
        href: designer.url,
    })).concat({
        name: 'View All',
        href: route('collections.index', 'designer'),
    }),
    categories: categories.map(category => ({
        name: category.name,
        href: category.url,
    })).concat({
        name: 'View All',
        href: route('collections.index', 'category'),
    }),
    specials: [
        {name: 'Just Arrived', href: route('collection.show', ['special', 'just-arrived'])},
        {name: 'On Sale', href: route('products.browse', {type: 'fabric', special: 'Sale'})},
        {name: 'Clearance', href: route('products.browse', {type: 'fabric', special: 'Clearance'})},
    ],
    cuts: [
        {name: 'Fat Quarters', href: route('products.browse', 'fat-quarter-packs')},
        {name: 'Precuts', href: route('products.browse', 'precuts')},
    ],
    social: [
        {
            name: 'Facebook',
            href: 'https://www.facebook.com/oldcountrystorefabrics',
            icon: defineComponent({
                render: () =>
                    h('svg', {fill: 'currentColor', viewBox: '0 0 24 24'}, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'Instagram',
            href: 'https://www.instagram.com/theoldcountrystore/',
            icon: defineComponent({
                render: () =>
                    h('svg', {fill: 'currentColor', viewBox: '0 0 24 24'}, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
        {
            name: 'Pinterest',
            href: 'https://www.pinterest.com/search/pins/?q=oldcountrystorefabrics.com',
            icon: defineComponent({
                render: () =>
                    h('svg', {fill: 'currentColor', viewBox: '0 0 128 100'}, [
                        h('path', {
                            'fill-rule': 'evenodd',
                            d: 'M 25.334 90 c 3.415 -2.853 8.943 -12.256 10.189 -17.048 c 0.671 -2.581 3.437 -13.114 3.437 -13.114 c 1.799 3.43 7.054 6.334 12.644 6.334 c 16.638 0 28.626 -15.301 28.626 -34.312 C 80.229 13.636 65.357 0 46.22 0 C 22.415 0 9.771 15.981 9.771 33.382 c 0 8.091 4.307 18.164 11.198 21.371 c 1.045 0.486 1.604 0.272 1.845 -0.738 c 0.183 -0.767 1.113 -4.513 1.532 -6.256 c 0.134 -0.557 0.068 -1.036 -0.383 -1.582 c -2.279 -2.764 -4.105 -7.848 -4.105 -12.589 c 0 -12.167 9.213 -23.94 24.909 -23.94 c 13.552 0 23.042 9.235 23.042 22.443 c 0 14.923 -7.536 25.261 -17.341 25.261 c -5.415 0 -9.469 -4.478 -8.169 -9.968 c 1.555 -6.558 4.569 -13.634 4.569 -18.367 c 0 -4.237 -2.274 -7.771 -6.98 -7.771 c -5.536 0 -9.982 5.727 -9.982 13.397 c 0 4.886 1.65 8.19 1.65 8.19 s -5.467 23.115 -6.469 27.421 c -1.109 4.764 -0.674 11.476 -0.191 15.84 L 25.334 90 z',
                            'clip-rule': 'evenodd',
                        }),
                    ]),
            }),
        },
    ],
}
</script>
