<template>
    <!-- Mobile menu -->
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-40 lg:hidden" @close="open = false">
            <TransitionChild as="template" enter="transition-opacity ease-linear duration-300"
                             enter-from="opacity-0" enter-to="opacity-100"
                             leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
                             leave-to="opacity-0">
                <div class="fixed inset-0 bg-black bg-opacity-25"/>
            </TransitionChild>

            <div class="fixed inset-0 z-40 flex">
                <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
                                 enter-from="-translate-x-full" enter-to="translate-x-0"
                                 leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                                 leave-to="-translate-x-full">
                    <DialogPanel
                        class="relative flex w-full max-w-sm flex-col overflow-x-clip overflow-y-auto bg-white pb-12 shadow-xl">
                        <div class="flex px-4 pt-5 pb-2">
                            <button type="button"
                                    class="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                                    @click="open = false">
                                <span class="sr-only">Close menu</span>
                                <PhX class="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>

                        <!-- Links -->
                        <TabGroup as="div" class="mt-2">
                            <div class="border-b border-gray-200">
                                <TabList class="-mb-px flex space-x-8 px-4">
                                    <Tab as="template" v-for="category in navigation.categories"
                                         :key="category.name" v-slot="{ selected }">
                                        <button
                                            :class="[selected ? 'text-indigo-600 border-indigo-600' : 'text-gray-900 border-transparent', 'flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium']">
                                            {{ category.shortName }}
                                        </button>
                                    </Tab>
                                </TabList>
                            </div>
                            <TabPanels as="template">
                                <TabPanel v-for="category in navigation.categories" :key="category.name"
                                          class="space-y-10 px-4 pt-10 pb-8">
                                    <div class="grid grid-cols-3 gap-x-4">
                                        <div v-for="item in category.featured" :key="item.name"
                                             class="group relative text-sm">
                                            <div
                                                class="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                                <img :src="item.imageSrc" :alt="item.imageAlt"
                                                     class="object-cover object-center"/>
                                            </div>
                                            <Link :href="item.href" class="mt-6 block font-medium"
                                                  :class="item.classes">
                                                <span class="absolute inset-0 z-10" aria-hidden="true"/>
                                                {{ item.name }}
                                            </Link>
                                            <p aria-hidden="true" class="mt-1">{{ item.action ?? 'Shop now' }}</p>
                                        </div>
                                    </div>
                                    <div v-for="section in category.sections" :key="section.name">
                                        <p :id="`${category.id}-${section.id}-heading-mobile`"
                                           class="font-medium text-gray-900">{{ section.name }}</p>
                                        <ul role="list"
                                            :aria-labelledby="`${category.id}-${section.id}-heading-mobile`"
                                            class="mt-6 flex flex-col space-y-6"
                                            :class="section.name ? `mt-6` : `none`">
                                            <li v-for="item in section.items" :key="item.name" class="flow-root">
                                                <Link :href="item.href"
                                                      class="-m-2 block p-2" :class="item.classes">{{ item.name }}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </TabPanel>
                            </TabPanels>
                        </TabGroup>

                        <div class="space-y-6 border-t border-gray-200 py-6 px-4">
                            <Link v-if="favorites.count > 0"
                                  :href="route('products.favorites')"
                                  class="flex items-center font-medium text-gray-700 hover:text-gray-800">
                                Favorites
                            </Link>

                            <Link v-if="designWall.count > 0"
                                  :href="route('products.design-wall')"
                                  class="flex items-center font-medium text-gray-700 hover:text-gray-800">
                                Design Wall
                            </Link>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <header class="sticky top-0 lg:relative z-10 bg-white border-b border-gray-200">
        <nav aria-label="Top" class="mx-auto container">
            <div class="">
                <div class="flex items-center">
                    <button type="button" class="rounded-md bg-white p-2 text-gray-400 lg:hidden"
                            @click="open = true">
                        <span class="sr-only">Open menu</span>
                        <PhList class="h-6 w-6" aria-hidden="true"/>
                    </button>

                    <!-- Logo -->
                    <div class="flex">
                        <LogoLink :href="route('home')"/>
                    </div>

                    <!-- Flyout menus -->
                    <PopoverGroup class="hidden lg:ml-8 lg:mr-2 lg:block lg:self-stretch">
                        <div class="flex h-full space-x-8">
                            <Popover v-for="category in navigation.categories" :key="category.name" class="flex"
                                     v-slot="{ open }">
                                <div class="relative flex">
                                    <PopoverButton
                                        :class="[open ? 'border-indigo-600 text-indigo-600' : 'border-transparent text-gray-700 hover:text-gray-800', 'relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out']">
                                        {{ category.name }}
                                    </PopoverButton>
                                </div>

                                <transition enter-active-class="transition ease-out duration-200"
                                            enter-from-class="opacity-0" enter-to-class="opacity-100"
                                            leave-active-class="transition ease-in duration-150"
                                            leave-from-class="opacity-100" leave-to-class="opacity-0">
                                    <PopoverPanel class="absolute inset-x-0 top-full text-sm text-gray-500">
                                        <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
                                        <div class="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true"/>

                                        <div class="relative bg-white">
                                            <div class="mx-auto max-w-7xl px-8">
                                                <div class="grid grid-cols-2 gap-y-10 gap-x-8 py-16">
                                                    <div class="col-start-2 grid grid-cols-3 gap-x-8">
                                                        <div v-for="item in category.featured" :key="item.name"
                                                             class="group relative text-base sm:text-sm">
                                                            <div
                                                                class="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                                                <img :src="item.imageSrc" :alt="item.imageAlt"
                                                                     class="object-cover object-center"/>
                                                            </div>
                                                            <Link :href="item.href"
                                                                  class="mt-6 block font-medium text-gray-900">
                                                                    <span class="absolute inset-0 z-10"
                                                                          aria-hidden="true"/>
                                                                {{ item.name }}
                                                            </Link>
                                                            <p aria-hidden="true" class="mt-1">{{ item.action ?? 'Shop now' }}</p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="row-start-1 grid grid-cols-3 gap-y-10 gap-x-8 text-sm">
                                                        <div v-for="section in category.sections"
                                                             :key="section.id">
                                                            <p v-if="section.name" :id="`${section.id}-heading`"
                                                               class="font-medium text-gray-900">{{
                                                                    section.name
                                                                }}</p>
                                                            <ul role="list"
                                                                :aria-labelledby="`${section.id}-heading`"
                                                                class="space-y-6 sm:space-y-4"
                                                                :class="section.name ? `mt-6 sm:mt-4` : ``">
                                                                <li v-for="item in section.items" :key="item.name"
                                                                    class="flex">
                                                                    <Link :href="item.href"
                                                                          class="hover:text-gray-800"
                                                                          :class="item.classes">{{
                                                                            item.name
                                                                        }}
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </PopoverPanel>
                                </transition>
                            </Popover>

                            <Link v-if="favorites.count > 0"
                                  :href="route('products.favorites')"
                                  class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">
                                Favorites
                            </Link>

                            <Link v-if="designWall.count > 0"
                                  :href="route('products.design-wall')"
                                  class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800">
                                Design Wall
                            </Link>
                        </div>
                    </PopoverGroup>

                    <div class="flex ml-4 flex-1 lg:flex-none lg:ml-auto items-center">
                        <Search/>

                        <!-- Profile -->
                        <div class="flex mx-2 lg:ml-4 xl:ml-6">
                            <Link :href="route('orders.index')" class="p-2 text-gray-400 hover:text-gray-500">
                                <span class="sr-only">Profile</span>
                                <PhUser class="h-6 w-6" aria-hidden="true"/>
                            </Link>
                        </div>

                        <!-- Cart -->
                        <div class="mx-2 flow-root xl:ml-6">
                            <Link :href="route('cart.show')" class="group -ml-2 flex items-center p-2">
                                <PhShoppingCartSimple
                                    class="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"/>
                                <span
                                    v-if="cart.count > 0"
                                    class="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                                    {{ cart.count }}
                                </span>
                                <span class="sr-only">items in cart, view bag</span>
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script setup>
import {computed, inject, ref} from 'vue';
import {
    Dialog,
    DialogPanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import {
    PhList,
    PhShoppingCartSimple,
    PhX,
    PhUser,
} from '@phosphor-icons/vue'
import {Link, usePage} from '@inertiajs/vue3';
import Search from "@/Components/Search.vue";
import LogoLink from "@/Components/LogoLink.vue";

const route = inject('route');
const favorites = computed(() => usePage().props.favorites);
const designWall = computed(() => usePage().props.designWall);
const cart = computed(() => usePage().props.cart);

const manufacturers = usePage().props.popular.manufacturer;
const designers = usePage().props.popular.designer;
const categories = usePage().props.popular.category;
const fatQuarters = usePage().props.popular.fatQuarter;
const precuts = usePage().props.precuts;
const featured = usePage().props.navigation;
const navigation = {
    categories: [
        {
            id: 'fabric',
            name: 'Shop Fabric',
            shortName: 'Fabric',
            featured: featured.fabric,
            sections: [
                {
                    id: 'manufacturers',
                    name: 'by Manufacturer',
                    items: manufacturers.map(manufacturer => ({
                        name: manufacturer.name,
                        href: manufacturer.url,
                        classes: 'text-gray-500',
                    })).concat({
                        name: 'View All',
                        href: route('collections.index', 'manufacturer'),
                        classes: 'text-gray-900',
                    }),
                },
                {
                    id: 'designers',
                    name: 'by Designer',
                    items: designers.map(designer => ({
                        name: designer.name,
                        href: designer.url,
                        classes: 'text-gray-500',
                    })).concat({
                        name: 'View All',
                        href: route('collections.index', 'designer'),
                        classes: 'text-gray-900',
                    }),
                },
                {
                    id: 'categories',
                    name: 'by Category',
                    items: categories.map(category => ({
                        name: category.name,
                        href: category.url,
                        classes: 'text-gray-500',
                    })).concat({
                        name: 'View All',
                        href: route('collections.index', 'category'),
                        classes: 'text-gray-900',
                    }),
                },
            ],
        },
        {
            id: 'specialty-cuts',
            name: 'Specialty Cuts',
            shortName: 'Special Cuts',
            featured: featured.specialty,
            sections: [
                {
                    id: 'fat-quarter-packs',
                    name: 'Fat Quarter Packs',
                    items: fatQuarters.map(fatQuarter => ({
                        name: fatQuarter.name,
                        href: fatQuarter.url,
                        classes: 'text-gray-500',
                    })).concat({
                        name: 'View All',
                        href: route('products.browse', 'fat-quarter-packs'),
                        classes: 'text-gray-900',
                    }),
                },
                {
                    id: 'precuts',
                    name: 'Precuts',
                    items: precuts.map(precut => ({
                        name: precut.name,
                        href: precut.url,
                        classes: 'text-gray-500',
                    })).concat({
                        name: 'View All',
                        href: route('products.browse', 'precuts'),
                        classes: 'text-gray-900',
                    }),
                },
                {
                    id: 'other-cuts',
                    items: [
                        {
                            name: 'Extra-Wide',
                            href: route('products.browse', {type: 'fabric', special: 'Extra-Wide'}),
                            classes: 'text-gray-900',
                        },
                        {
                            name: 'Kits',
                            href: route('products.browse', 'kits'),
                            classes: 'text-gray-900',
                        },
                    ],
                }
            ],
        },
        {
            id: 'notions',
            name: 'Notions & More',
            shortName: 'Notions',
            featured: featured.more,
            sections: [
                {
                    id: 'notions-1',
                    items: [
                        {
                            name: 'Crafts',
                            href: route('products.browse', 'crafts'),
                            classes: 'text-gray-900',
                        },
                        {
                            name: 'Interfacing',
                            href: route('products.browse', 'interfacing'),
                            classes: 'text-gray-900',
                        },
                        {
                            name: 'Gifts',
                            href: route('products.browse', 'gifts'),
                            classes: 'text-gray-900',
                        },
                        {
                            name: 'Kits',
                            href: route('products.browse', 'kits'),
                            classes: 'text-gray-900',
                        },
                        {
                            name: 'Magazines',
                            href: route('products.browse', 'magazines'),
                            classes: 'text-gray-900',
                        },
                        {
                            name: 'NeedleArt',
                            href: route('products.browse', 'needleart'),
                            classes: 'text-gray-900',
                        },
                    ],
                },
                {
                    id: 'notions-2',
                    items: [
                        {
                            name: 'Notions',
                            href: route('products.browse', 'notions'),
                            classes: 'text-gray-900',
                        },
                        {
                            name: 'Patterns',
                            href: route('products.browse', 'patterns'),
                            classes: 'text-gray-900',
                        },
                        {
                            name: 'Threads',
                            href: route('products.browse', 'threads'),
                            classes: 'text-gray-900',
                        },
                        {
                            name: 'Sewing Machines',
                            href: route('products.browse', 'sewing-machines'),
                            classes: 'text-gray-900',
                        },
                        {
                            name: 'Books',
                            href: route('products.browse', 'books'),
                            classes: 'text-gray-900',
                        },
                        {
                            name: 'Gift Cards',
                            href: route('products.browse', 'gift-cards'),
                            classes: 'text-gray-900',
                        },
                    ],
                },
            ],
        },
    ],
}

const open = ref(false)
</script>
