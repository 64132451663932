<script setup>
import {ref, computed, watch} from 'vue';
import Navigation from "@/Layouts/Partials/Navigation.vue";
import Footer from "@/Layouts/Partials/Footer.vue";
import {PhCheckCircle, PhX} from '@phosphor-icons/vue';
import {Link, usePage} from '@inertiajs/vue3';
import LegacyLink from "@/Layouts/Partials/LegacyLink.vue";

const showFlash = ref(false);
const flash = computed(() => usePage().props.flash);
watch(flash,
    () => {
        if (flash.value.href !== window.location.href) {
            showFlash.value = flash.value.message != null;
            setTimeout(() => {
                showFlash.value = false;
            }, 5000);
        }
    }
);

</script>

<template>
    <div>
        <LegacyLink />
        <Navigation/>
        <div v-if="showFlash"
             aria-live="assertive"
             class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50">
            <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
                <transition enter-active-class="transform ease-out duration-300 transition"
                            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                            leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                            leave-to-class="opacity-0">
                    <div
                        class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div class="p-4">
                            <div class="flex items-start">
                                <div class="flex-shrink-0">
                                    <PhCheckCircle weight="fill" class="h-6 w-6 text-green-400" aria-hidden="true"/>
                                </div>
                                <div class="ml-3 w-0 flex-1 pt-0.5">
                                    <p class="text-sm font-medium text-gray-900">{{ flash.message }}</p>
                                    <p class="mt-1 text-sm text-gray-500">
                                        <Link v-if="flash.href && flash.action" :href="flash.href"
                                              class="whitespace-nowrap font-medium">
                                            {{ flash.action }}
                                            <span aria-hidden="true"> &rarr;</span>
                                        </Link>
                                    </p>
                                </div>
                                <div class="ml-4 flex flex-shrink-0">
                                    <button type="button" @click="showFlash = false"
                                            class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                        <span class="sr-only">Close</span>
                                        <PhX class="h-5 w-5" aria-hidden="true"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="min-h-screen">
            <slot/>
        </div>
        <Footer/>
    </div>
</template>
